//called from Login.js
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Status from "../components/Status";
import { useNavigate } from "react-router-dom";
import { ArrowBack, CheckCircleOutline } from "@material-ui/icons";
import Loader from "../Loader";
import TextfieldStyled from "../components/TextfieldStyled/TextfieldStyled";
import styles from "./loginHelp.module.css";


//its a Material-UI hook for defining custom styles for components
const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
}));

//its the main component function that holds the entire login assistance form
function LoginHelp() {

  const classes = useStyles();
  const navigate=useNavigate();

  const [actionstatus, setactionstatus] = useState("invisible");
  const [loading, setloading] = useState(false);
  const [message, setMessage] = useState();

  const [enterOTP, setEnterOTP] = useState(false);
  const [OTPmatched, setOTPmatched] = useState(false);
  const [success, setsuccess] = useState(false);

  const [userName, setUserName] = useState(); //initial value undefined
  const [otpValue, setOtpValue] = useState();
  const [credentialId, setCredentialId] = useState();
  const [userProfile, setUserProfile] = useState();
  const [emailVerified, setEmailVerified] = useState(false);
  const [jwtToken, setJwtToken] = useState();
  const [otpVerification, setOtpVerification] = useState();

  const [errorMessage, setErrorMessage] = useState("");

  const checkUserName = () => {
   // console.log("userName", userName);
    if (userName !== undefined) {
      const requestOptions = {
        method: "GET",
        headers: { "content-type": "application/json" },
      };
      setloading(true);
      setCredentialId();
      fetch(
        `${process.env.REACT_APP_ENDPOINT}/credentials/userexists/${userName}`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            setloading(false);
            //console.log("Error:", response);
            setErrorMessage("not found");
          } else {
            return response.json();
          }
        })
        .then((data) => {
          //console.log("Data:", data);
          if (data !== undefined) {
            //setloading(false);
            setCredentialId(data?.id);
            setErrorMessage("");
          }
        })
        .catch((error) => {
          console.log("error in getting data");
        });
    }
  };
  //console.log("credentialId:",credentialId)
  useEffect(() => {
    if (credentialId !== undefined) {
      // console.log("credentialId",credentialId)
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      setErrorMessage("");
      fetch(
        `${process.env.REACT_APP_ENDPOINT}/prof?credentialid=${credentialId}`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            console.log("Error in getting error");
          }
          return response.json();
        })
        .then((data) => {
          //data conatins array of objects
          if (data[0]) {
            //console.log("ppdata:",data)
            setUserProfile(data[0]);
          } else {
            //console.log("pp:",data)
            setErrorMessage("no profile found");
            setloading(false);
          }
        })
        .catch((error) => {
          console.log("error in getting data");
        });
    }
  }, [credentialId]);
  //console.log("userprofile",userProfile)
  useEffect(() => {
    if (userProfile !== undefined && userProfile) {
      if (userProfile?.email) {
        //console.log("checking email", userProfile.email);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isEmailValid = emailRegex.test(userProfile.email);
        if (isEmailValid) {
          console.log("Email is in a valid format:", userProfile.email);
          setEmailVerified(true);
        } else {
          console.log("Email is not in a valid format:", userProfile.email);
          setEmailVerified(false);
          setErrorMessage("email not valid");
          setloading(false);
        }
      }
    } else {
      // setErrorMessage("no profile found");
    }
    //setErrorMessage("")
  }, [userProfile]);

  useEffect(() => {
    if (emailVerified) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: credentialId,
          action: "Reset Password",
        }),
      };
      fetch(`${process.env.REACT_APP_ENDPOINT}/otp`, requestOptions)
        .then((response) => {
          if (!response.ok) {
            console.log("Error in getting error");
          }
          return response.json();
        })
        .then((data) => {
          setUserName("");
          setloading(false);
          setEnterOTP(true);
          setJwtToken(data);
          setEmailVerified(false);
        })
        .catch((error) => {
          console.log("error in getting data");
        });
    }
  }, [emailVerified]);
  //console.log("jwt token",jwtToken?.token)

  const verifyOTP = () => {
    // console.log("otp:", otpValue);
    //console.log("jwt token:::", jwtToken);
    setErrorMessage("");
    if (jwtToken !== undefined && otpValue !== undefined) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken.token}`,
        },
      };
      setloading(true);
      fetch(
        `${process.env.REACT_APP_ENDPOINT}/otp/verify/${otpValue}`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            console.log("Error in getting error");
            setErrorMessage("wrong otp");
            setloading(false);
          }
          //console.log("verifyOTP response:", response);
          return response.text();
        })
        .then((data) => {
          //console.log("verifyOTP", data);
          setOtpVerification(data);
        })
        .catch((error) => {
          console.log("error in getting data");
        });
    }
  };
  //console.log("verifyOTP Message:",otpVerification)
  useEffect(() => {
    if (otpVerification !== undefined) {
      if (otpVerification === "Otp verified") {
        setOTPmatched(true);
        setloading(false);
        setUserName("");
      }
    }
  }, [otpVerification]);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checkStatus, setCheckStatus] = useState("");
  useEffect(() => {
    if (password !== "" && confirmPassword !== "") {
      if (confirmPassword === password) {
        setCheckStatus("same");
      } else {
        setCheckStatus("not same");
      }
    }
  }, [confirmPassword, password]);

  useEffect(() => {
    if (password.length < 8) {
      //console.log("password should be of 8 characters")
      setErrorMessage("password small");
    } else {
      setErrorMessage("");
    }
  }, [password]);

  const [userpassword, setUserpassword] = useState();
  useEffect(() => {
    if (checkStatus === "same") {
      setUserpassword(confirmPassword);
    }
  }, [checkStatus, confirmPassword]);

  const saveNewPassword = () => {
    //console.log("user password",userpassword)
    if (OTPmatched && jwtToken !== undefined) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken.token}`,
        },
        body: JSON.stringify({
          newpassword: userpassword,
        }),
      };
      setloading(true);
      fetch(
        `${process.env.REACT_APP_ENDPOINT}/credentials/resetpassword`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            console.log("Error in getting error");
          }
          return response.json();
        })
        .then((data) => {
          //console.log("final response: ", data);
          if (data.resp === "Password reset successful") {
            setsuccess(true);
            setloading(false);
          }
        })
        .catch((error) => {
          console.log("error in getting data");
        });
    }
  };

  return (
    <div className={styles.reset_container}>
      {/*container for the forgot password flow*/}
      <div className={styles.formContainer}>
        <Status
          actionstatus={actionstatus}
          setactionstatus={setactionstatus}
          message={message}
        />

        <div className={styles.heading}>
          <IconButton aria-label="delete">
            <ArrowBack
              onClick={() => {
                if (!enterOTP) {
                  navigate("/login")
                }
                if (enterOTP) {
                  setEnterOTP(false);
                 
                }
                if (OTPmatched) {
                  setOTPmatched(false);
                  setEnterOTP(true);
                  navigate("/login")
                }
                if (success) {
                  setOTPmatched(false);
                  setEnterOTP(false);
                  navigate("/login")
                }
              }}
            />
          </IconButton>
          <Typography
            style={{ fontWeight: "400", color: "#6153BD" }}
            variant="h6"
          >
            Forgot Password
          </Typography>
        </div>
        {enterOTP ? ( //by default its false it will be true when user enters email/userid/phone
          //will be shown when users enters OTP
          <div className={styles.forgetPasswordFields}>
            {OTPmatched ? ( //its false by default it will be true when user enters OTP and it matchs
              <>
                {/*will be shown when OTP is matched and API call is made successfully */}
                {success ? ( //by default its false
                  //this component will be called when users enters new password
                  <PasswordSuccess
                    classes={classes}
                  />
                ) : (
                  //This will be shown for user to enter new password
                  <div className={styles.reset_password_container}>
                    <div>
                      <TextfieldStyled
                        placeholder="Please Enter The New Password"
                        type="password"
                        width="100%"
                        color="white"
                        label=""
                        onChangehandler={(e) => setPassword(e.target.value)}
                        name="password"
                        value={password}
                        error={errorMessage === "password small"}
                        helpertext={
                          errorMessage === "password small" &&
                          "Password must be atleast of 8 characters"
                        }
                      />
                    </div>
                    <div>
                      <TextfieldStyled
                        placeholder="Please Re-Enter The Password"
                        type="password"
                        width="100%"
                        color="white"
                        label=""
                        onChangehandler={(e) =>
                          setConfirmPassword(e.target.value)
                        }
                        name="confirmPassword"
                        value={confirmPassword}
                        error={checkStatus === "not same"}
                        helpertext={
                          checkStatus === "not same" && "Passwords do not match"
                        }
                      />
                    </div>
                    <div>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        style={{
                          textTransform: "none",
                          boxSizing: "border-box",
                          color: "#6153BD",
                          borderColor: "#6153BD",
                          fontWeight: "300",
                        }}
                        onClick={saveNewPassword}
                      >
                        {!loading ? (
                          "REST PASSWORD"
                        ) : (
                          <>
                            RESET PASSWORD
                            <div className={styles.loader}>
                              <Loader color="#6153BD" />
                            </div>
                          </>
                        )}
                      </Button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {enterOTP && (
                  <div className={styles.text}>
                    <p style={{ fontWeight: "500", color: "#2E7D32" }}>
                      OTP sent to the registered email-address
                    </p>
                  </div>
                )}
                {/*this will shown to user for entering OTP code received via email/phone */}
                <div>
                  <TextfieldStyled
                    placeholder="Please Enter The 6-Digit Code"
                    name="otpValue"
                    width="100%"
                    color="white"
                    label=""
                    onChangehandler={(e) => setOtpValue(e.target.value)}
                    value={otpValue}
                    error={errorMessage === "wrong otp"}
                    helpertext={
                      errorMessage === "wrong otp"
                        ? "Entered OTP Is Wrong/Used"
                        : ""
                    }
                  />
                </div>
                <div>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    style={{
                      textTransform: "none",
                      boxSizing: "border-box",
                      color: "#6153BD",
                      borderColor: "#6153BD",
                      fontWeight: "300",
                    }}
                    onClick={verifyOTP}
                  >
                    {!loading ? (
                      "SUBMIT CODE"
                    ) : (
                      <>
                        SUBMIT CODE
                        <div
                          className={styles.loader}
                        >
                          <Loader color="#6153BD" />
                        </div>
                      </>
                    )}
                  </Button>
                </div>
              </>
            )}
          </div>
        ) : (
          //this will be shown to user for entering email/phone/username on whihc they will receive OTP
          <div className={styles.forgetPasswordFields}>
            <div>
              <TextfieldStyled
                placeholder="Please enter your username"
                name="userName"
                width="100%"
                color="white"
                label=""
                onChangehandler={(e) => setUserName(e.target.value)}
                value={userName}
                error={
                  errorMessage === "not found" ||
                  errorMessage === "email not valid" ||
                  errorMessage === "no profile found"
                }
                helpertext={
                  errorMessage === "not found"
                    ? "User does not exist"
                    : errorMessage === "email not valid"
                    ? "Linked Email Is Invalid/Didn't Exist"
                    : errorMessage === "no profile found"
                    ? "Profile Not Created Yet"
                    : ""
                }
              />
            </div>
            <div>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={checkUserName}
                style={{
                  textTransform: "none",
                  boxSizing: "border-box",
                  color: "#6153BD",
                  borderColor: "#6153BD",
                  fontWeight: "300",
                }}
              >
                {!loading ? (
                  "SEND RESET CODE"
                ) : (
                  <>
                    SEND RESET CODE
                    <div className={styles.loader}>
                      <Loader color="#6153BD" />
                    </div>
                  </>
                )}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function PasswordSuccess({ classes }) {
  const navigate=useNavigate()
  return (
    <div className={styles.back_to_login}>
      <div className={styles.container}>
        <CheckCircleOutline style={{ color: "#2E7D32" }} fontSize="large" />
        <div className={styles.text}>
          <p style={{ fontWeight: "500" }}>Password Reset Successfully</p>
          <p>Please go to the login page to sign in</p>
        </div>
      </div>
      <div>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          className={classes.button}
          style={{
            textTransform: "none",
            boxSizing: "border-box",
            color: "#6153BD",
            borderColor: "#6153BD",
            fontWeight: "300",
          }}
          onClick={() => {
            navigate("/login")
          }}
        >
          GO TO LOGIN PAGE
        </Button>
      </div>
    </div>
  );
}

export default LoginHelp;
